jQuery(function() {
  jQuery(".utopia").validationEngine('attach', {
    promptPosition: "topLeft",
    scroll: false
  });

  // Check or uncheck all checkboxes
  $('.utopia-check-all').click(function() {
    $(this).closest('.table').find('input:checkbox').attr('checked', $(
      this).is(':checked'));
  });
  jQuery("#validation").validationEngine();
  $("#phone").mask("(999) 9999999999");


});
